import React from "react"
import Layout from "../components/layout"
import Navigation2 from "../components/navigation2"

import HomeSocios from "../components/home-socios"
import HomeContacto from "../components/home-contacto"
import Aldo from "../images/aldo.jpg"

const IndexPage = () => (
  <Layout>
    <div className="w-full lg:w-10/12 mx-auto">
      <Navigation2 />

      <div className="w-full lg:flex mx-auto">
        <div className="hidden lg:block w-full lg:w-1/3 py-3 px-5 ">
          <img
            src={Aldo}
            alt="Aldo Milano"
            className=" w-full object-cover lg:p-5 lg:pt-0"
          />
        </div>
        <div className="w-full lg:w-2/3 px-5 lg:px-0 mt-10 lg:mt-0">
          <h1 className="text-3xl">Aldo Milano Sánchez</h1>

          <p className="mt-8 text-gray-800">
            La firma desarrolla su práctica profesional, exclusivamente, en las
            distintas áreas del Derecho público, prestando servicios de
            consultoría, auditoría y litigio en asuntos de particular
            dificultad. Asesoramos a empresas cuyos proyectos o actividades se
            relacionan con el Estado o alguna de sus instituciones.
          </p>

          <p className="mt-8 text-gray-800">
            Nuestros integrantes poseen una amplia formación profesional,
            intensa actividad académica y de investigación, además de una
            extensa experiencia profesional en litigios de constitucionalidad,
            contencioso-administrativos, arbitrajes, consultorías y montaje de
            relaciones contractuales de elevada complejidad.
          </p>

          <p className="mt-8 text-gray-800">
            Nace como producto de la inquietud de sus socios fundadores, de
            crear una firma de abogacía, experta en Derecho Público, capaz de
            satisfacer las exigencias del mercado meta, incorporando criterios
            empresariales en su práctica profesional y el desarrollo de una
            política de calidad.
          </p>

          <p className="mt-8 text-gray-800">
            Aportamos a nuestros clientes los beneficios que se derivan de un
            riguroso criterio de calidad, sustentados en nuestros principios
            rectores:
          </p>

          <ul className="mt-8 text-gray-800 list-disc ml-5 lg:ml-0">
            <li>Certeza de cumplir lo prometido.</li>
            <li>Orientación a resultados.</li>
            <li>Elevada competencia e integridad profesional.</li>
            <li>Servicio dedicado y disponible</li>
            <li>Información periódica, suficiente y pertinente.</li>
          </ul>
        </div>
      </div>

      <HomeSocios />
    </div>

    <HomeContacto />
  </Layout>
)

export default IndexPage
